import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import btcBat from "../../assets/images/btcBatText.png";
import loginImage from "../../assets/images/Batprofile1.png";
import Loader from "../../components/smallLoader";

const Login = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleLogin = () => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Set content type to JSON
      },
      body: JSON.stringify({ password }), // Convert the request body to JSON
    };
    fetch("https://api.bitcoinbats.com/app.v1/api/login", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        localStorage.setItem("isLogin", JSON.stringify(data));
        setLoading(false); // Handle the response data
        navigate("/");
      });
  };
  return (
    <div className="login-container">
      <div className="btc-text-wrapper">
        <img src={btcBat} alt="btc bat" className="btc-text" />
      </div>
      <div className="login-form-container">
        <form onSubmit={handleSubmit} className="login-from-main">
          <img src={loginImage} alt="btc bat" className="" />
          <input
            type="password"
            id="password"
            className="login-input"
            placeholder="Enter your twitter username"
            // placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" className="login-button" onClick={handleLogin}>
            {loading ? <Loader /> : "  Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
