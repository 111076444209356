import React from "react";
import {useNavigate} from "react-router-dom";
import ImageFrame from "../../components/ImageFrame";
import "./styles.css";

const Links = () => {
  const navigate = useNavigate();
  const handleClose = () => navigate("/");

  const link = (
    <a
      href="https://x.com/BTCBats?t=26MtwA23_zOQjn3pO12M6w&s=09"
      target="_blank"
    >
      "https://x.com/BTCBats?t=26MtwA23_zOQjn3pO12M6w&s=09"
    </a>
  );

  return (
    <div className="links-container">
      <ImageFrame
        title="Links"
        content={link}
        className="large-text"
        frameSize="medium-frame"
        handleClose={handleClose}
        closeIcon={true}
      />
    </div>
  );
};

export default Links;
