import React from "react";
import { useNavigate } from "react-router-dom";
import ImageFrame from "../../components/ImageFrame";
import memes1 from "../../assets/images/memes/Batfaces1-1.png";
import memes2 from "../../assets/images/memes/ILOVEBITCOIN-1.gif";
import memes3 from "../../assets/images/memes/LETSQUEACK-1.gif";
import memes4 from "../../assets/images/memes/Picsart_24-10-09_20-30-49-154.jpg";
import memes5 from "../../assets/images/memes/Picsart_24-10-09_20-31-35-250.jpg";
import memes6 from "../../assets/images/memes/Picsart_24-10-09_20-32-05-385.jpg";
import memes7 from "../../assets/images/memes/Picsart_24-10-09_20-32-31-538.jpg";
import memes8 from "../../assets/images/memes/Picsart_24-10-09_20-33-39-536.jpg";
import memes9 from "../../assets/images/memes/Picsart_24-10-09_20-34-39-279.jpg";
import memes10 from "../../assets/images/memes/Picsart_24-10-09_20-35-22-263.jpg";
import memes11 from "../../assets/images/memes/Picsart_24-10-09_20-37-23-065.jpg";
import memes12 from "../../assets/images/memes/Picsart_24-10-09_20-38-14-990.jpg";
import memes13 from "../../assets/images/memes/Picsart_24-10-09_20-39-43-322.jpg";
import memes14 from "../../assets/images/memes/Picsart_24-10-09_20-41-41-066.jpg";
import memes15 from "../../assets/images/memes/Picsart_24-10-09_20-43-57-324.jpg";
import memes16 from "../../assets/images/memes/Picsart_24-10-09_20-47-14-590.jpg";
import memes17 from "../../assets/images/memes/Picsart_24-10-09_20-47-46-135.jpg";

import "./styles.css";

const memesList = (
  <ul className="memes-content">
    <li>
      <img src={memes1} alt="memes" />
    </li>
    <li>
      <img src={memes2} alt="memes" />
    </li>
    <li>
      <img src={memes3} alt="memes" />
    </li>
    <li>
      <img src={memes4} alt="memes" />
    </li>
    <li>
      <img src={memes5} alt="memes" />
    </li>
    <li>
      <img src={memes6} alt="memes" />
    </li>
    <li>
      <img src={memes7} alt="memes" />
    </li>
    <li>
      <img src={memes8} alt="memes" />
    </li>
    <li>
      <img src={memes9} alt="memes" />
    </li>
    <li>
      <img src={memes10} alt="memes" />
    </li>
    <li>
      <img src={memes11} alt="memes" />
    </li>
    <li>
      <img src={memes12} alt="memes" />
    </li>
    <li>
      <img src={memes13} alt="memes" />
    </li>
    <li>
      <img src={memes14} alt="memes" />
    </li>
    <li>
      <img src={memes15} alt="memes" />
    </li>
    <li>
      <img src={memes16} alt="memes" />
    </li>
    <li>
      <img src={memes17} alt="memes" />
    </li>
  </ul>
);

const Memes = () => {
  const navigate = useNavigate();
  const handleClose = () => navigate("/");
  return (
    <div className="memes-container ">
      <ImageFrame
        title="Memes"
        content={memesList}
        handleClose={handleClose}
        frameSize="medium-frame memes-frame"
        closeIcon={true}
      />
    </div>
  );
};

export default Memes;
