import React, {createContext, useContext, useState} from "react";

const AudioContext = createContext();

export const AudioProvider = ({children}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => setIsPlaying(true);
  const handleStop = () => setIsPlaying(false);

  return (
    <AudioContext.Provider value={{isPlaying, handlePlay, handleStop}}>
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => useContext(AudioContext);
