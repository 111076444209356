import { RouterProvider } from "react-router-dom";
import router from "./routes";
import AudioPlayer from "./components/AudioPlayer";
import { AudioProvider } from "./audioContext";

import "./App.css";

function App() {
  return (
    <AudioProvider>
      <AudioPlayer>
        <RouterProvider router={router} />
      </AudioPlayer>
    </AudioProvider>
  );
}

export default App;
