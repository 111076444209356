import React from "react";
import { useNavigate } from "react-router-dom";
import ImageFrame from "../../components/ImageFrame";
import "./styles.css";

const manifestoPara = (
  <>
    <p>
      <b> The Night Belongs to Us</b>
      <br></br> In the shadows of Bitcoin, beneath the bridges of the
      decentralized world, Bitcoin Bats emerge. Not everyone sees them, not
      everyone knows them, but those who do… well, let’s just say they’ve
      already caught a whiff of what’s coming. Bats can smell the excitement in
      the air—you can’t hide from that! Miss it, and you might just get bitten
      by their fangs, transforming into a true psycho ordinal enjoyer.
    </p>
    <p>
      The First of Its Kind Bitcoin Bats are no ordinary creatures. Born in the
      depths of darkness but destined for the brilliance of the Bitcoin moon,
      they represent the first-ever bats on Bitcoin and Ordinals. As the night
      grows deeper and the crypto world sleeps, Bitcoin Bats remain vigilant,
      soaring through the Bitcoin landscape. With wings that stretch across the
      Ordinals and a spirit that soars higher than any price chart, they’re here
      to make an impact.
    </p>
    <p>
      {" "}
      <b>No Roadmaps, Just Bat Maps</b> <br></br> Like true nocturnal
      adventurers, Bitcoin Bats fly without the constraints of roadmaps or
      corporate promises. They soar only with the things that will benefit their
      community. We don’t need utility when we possess the power of humor—and
      the memes resonate louder than any whitepaper. Bitcoin Bats live by one
      guiding principle: “If you’re not having fun, you might just be missing
      the point.” Life on Bitcoin is fraught with risks, and who better to
      navigate that volatility than a creature of the night? They thrive where
      others falter, gliding through the shadows while the world below is
      unaware .{" "}
    </p>
    <p>
      <b>The Mission</b> <br></br>
      The mission is simple: Save the Bitcoin world one meme at a time. With
      Bitcoin Bats leading the charge, we’re not merely participants; we’re here
      to meme our way to greatness. Our art will be exceptional and meticulously
      crafted, creating a delightful chaos that resonates through the ages. We
      will ensure that Bitcoin Bats stands among the top collections in Bitcoin
      and Ordinals, recognized for its unique creativity and vibrancy. While we
      may fly under the radar, our presence will be undeniable. Bitcoin Bats
      exist to safeguard the Bitcoin realm, navigate chaos, and ensure that
      every bat finds its rightful place under the moonlight.
    </p>{" "}
    <p>
      {" "}
      <b>You Can't Hide</b> <br></br>
      If you’re here, you’re already part of the swarm. Whether you’re a bat or
      simply an enthusiast of chaos, you’re joining something larger. Bitcoin
      Bats aren’t just Ordinals—they’re a call to those who understand that in
      this cryptic realm, only the bold thrive. Smell the energy in the air and
      embrace the night; Bitcoin Bats are always watching. Miss out, and you’ll
      feel the bite of our fangs, awakening your inner psycho ordinal enjoyer.
    </p>
    <p>
      {" "}
      <b>Ready to Fly?</b>
      <br></br> The Bitcoin sky is ours to explore, and the night is filled with
      potential. Don’t hesitate—Bitcoin Bats are waiting. Lift your head, look
      to the moon, and prepare to soar with the first bats of Bitcoin. Together,
      we’ll share laughter, spread memes, and conquer the night.
    </p>
  </>
);

const Manifesto = () => {
  const navigate = useNavigate();
  const handleClose = () => navigate("/");

  return (
    <div className="manifesto-container">
      <ImageFrame
        title="Bats manifesto"
        content={manifestoPara}
        handleClose={handleClose}
        className="manifesto"
        frameSize="large-frame"
        closeIcon={true}
      />
    </div>
  );
};

export default Manifesto;
