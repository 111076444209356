import React from "react";
import flyingBat from "../../assets/images/flyingBat.png";
import "./styles.css";

function NotFound() {
  return (
    <div className="not-found">
      <div className="not-found-text">
        <h1>404</h1>
        <p>Oops! The bats couldn't find this page.</p>
        <a href="/">Go back to safety!</a>
      </div>
      <div className="bat-flying">
        <img src={flyingBat} alt="flying bat" />
      </div>
    </div>
  );
}

export default NotFound;
