import React from "react";
import {useNavigate} from "react-router-dom";
import ImageFrame from "../../components/ImageFrame";
import "./styles.css";

const collabsPara = (
  <p>
    Are you looking for collaboration or seeking Batlist spots for your
    community? Collaborations are now open for a limited time. Don’t hesitate to
    send a message with all the collaboration details via our official account
    on X.
  </p>
);

const Collabs = () => {
  const navigate = useNavigate();
  const handleClose = () => navigate("/");

  return (
    <div className="collabs-container">
      <ImageFrame
        title="Collabs"
        content={collabsPara}
        frameSize="small-frame"
        className="large-text"
        handleClose={handleClose}
        closeIcon={true}
      />
    </div>
  );
};

export default Collabs;
