import React, { Children, useState } from "react";
import Close from "../../assets/images/close.png";
import "./styles.css";
import CircularLoader from "../Loader";

const BatlistComponent = ({
  title,
  handleClose,
  content,
  className,
  isBatList,
  frameSize,
  isSocialForm,
  closeIcon,
  updateData,
}) => {
  const { id, points, username } = JSON.parse(localStorage.getItem("isLogin"));
  const [loader, setLoader] = useState(false);
  const [tweet, setTweet] = useState("");

  const [address, setAddress] = useState("");
  const [success, setSuccess] = useState(false);
  const [success1, setSuccess1] = useState(false);
  const handleSubmit = () => {
    setLoader(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Set content type to JSON
      },
      body: JSON.stringify({ username, tweet, id }), // Convert the request body to JSON
    };
    fetch("https://api.bitcoinbats.com/app.v1/api/submitTweet", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("isLogin", JSON.stringify(data));
        updateData(data.points);
        setTweet("");

        setSuccess(true);
        setLoader(false);
      });
  };
  const handleSubmitAddress = () => {
    setLoader(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Set content type to JSON
      },
      body: JSON.stringify({ address, id }), // Convert the request body to JSON
    };
    fetch(
      "https://api.bitcoinbats.com/app.v1/api/submitAddress",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // updateData(data.points);
        setAddress("");
        setSuccess1(true);
        setLoader(false);
      });
  };
  const isManifesto = className === "manifesto";
  let collabs = "";
  let collabsTitle = "";
  let memesClose = "";
  let memesTitle = "";
  let aboutUs = "";
  let aboutusClose = "";
  let aboutusTitle = "";
  if (title === "Collabs") {
    collabs = "collabs-close";
    collabsTitle = "collabs-title";
  }
  if (title === "Memes") {
    memesClose = "memes-close";
    memesTitle = "memes-title";
  }
  if (title === "about bitcoin bats") {
    aboutUs = "about-frame";
    aboutusClose = "aboutus-close";
    aboutusTitle = "aboutus-title";
  }

  return (
    <div className={`frame-container ${frameSize} ${aboutUs}`}>
      <div
        className={
          isManifesto
            ? "manifesto-frame-title"
            : `frame-title ${collabsTitle} ${memesTitle} ${aboutusTitle}`
        }
      >
        <h1>{title}</h1>
      </div>
      {closeIcon && (
        <div
          className={
            isManifesto
              ? "close-icon close-icon-large testing"
              : ` close-icon close-icon-small ${collabs} ${memesClose} ${aboutusClose}`
          }
          onClick={handleClose}
        >
          <img src={Close} alt="close" />
        </div>
      )}
      <div
        className="frame-content"
        style={{ justifyContent: isSocialForm ? "start" : "center" }}
      >
        <div className={`para ${className}`}>{content}</div>

        {isBatList && (
          <div className="input-container">
            <input
              type="text"
              placeholder="Enter your ordinal Address"
              onChange={(e) => setAddress(e.target.value)}
            />
            <div className="submit-wrapper">
              <p>Submit your ordinal address</p>
              {loader ? (
                <CircularLoader />
              ) : (
                <button
                  type="submit"
                  className="submit-button"
                  onClick={handleSubmitAddress}
                >
                  Submit
                </button>
              )}
            </div>
            {success1 && (
              <div className="success-message">Submitted Successfully</div>
            )}
          </div>
        )}
        {isSocialForm && (
          <div className="input-container">
            {/* <div className="social-input">
              <label>Your twitter @ :</label>
              <input
                type="text"
                name="twitter"
                placeholder="Enter your twitter handle"
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              />
            </div> */}
            <div className="social-input">
              <label style={{ marginTop: "1rem" }}>Submit your tweet :</label>
              <input
                type="text"
                name="tweet"
                placeholder="Enter Tweet URL"
                value={tweet}
                onChange={(e) => setTweet(e.target.value)}
              />
            </div>
            <div className="error-message">
              More points, more chances for the BATLIST, more points more
              chances for (**.**.**)
              {success}
            </div>
            {success && (
              <div className="success-message">Submitted Successfully</div>
            )}
            {loader ? (
              <CircularLoader />
            ) : (
              <div className="submit-wrapper">
                <button
                  type="submit"
                  style={{ marginTop: "1.6rem" }}
                  className="submit-button"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BatlistComponent;
