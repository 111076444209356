import React from "react";
import "./style.css"; // Import CSS for loader styling

const CircularLoader = () => {
  return (
    <div className="overlay">
      <div className="loader"></div>
    </div>
  );
};

export default CircularLoader;
