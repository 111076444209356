import NavItem from "../../components/NavItems";
import home from "../../assets/images/homeBtn.png";
import batlist from "../../assets/images/batlistBtn.png";
import aboutBat from "../../assets/images/aboutBatBtn.png";
import links from "../../assets/images/linksBtn.png";
import manifesto from "../../assets/images/manifestoBtn.png";
import collabs from "../../assets/images/collabsBtn.png";
import music from "../../assets/images/musicBtn.png";
import memes from "../../assets/images/memesBtn.png";
import flyingBat from "../../assets/images/flyingBat.png";

import "./styles.css";

const navLinks = [
  { text: "Home", link: "/logout", icon: home },
  { text: "Batlist", link: "batlist", icon: batlist },
  { text: "About Bats", link: "about", icon: aboutBat },
  { text: "Links", link: "links", icon: links },
  { text: "Manifesto", link: "manifesto", icon: manifesto },
  { text: "Collabs", link: "collabs", icon: collabs },
  { text: "Music", link: "/", icon: music },
  { text: "Memes", link: "memes", icon: memes },
];

function Home() {
  return (
    <div className="main-content">
      <div className="nav">
        {navLinks?.map(({ text, link, icon }) => (
          <NavItem text={text} link={link} icon={icon} key={text} is />
        ))}
      </div>

      <div className="bat-image">
        <img src={flyingBat} alt="bat" />
      </div>
    </div>
  );
}

export default Home;
