import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  localStorage.removeItem("isLogin");
  useEffect(() => {
    navigate("/login");
  }, [navigate]);
  return null;
};

export default Logout;
