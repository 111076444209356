import { Outlet } from "react-router-dom";
import bgVideo from "../../assets/video/background-video.mp4";
import bitcoinText from "../../assets/images/BitcoinBatText.png";
import bg from "../../assets/images/bg.png";
import "./style.css";
import { useEffect, useRef, useState } from "react";

const Layout = () => {
  return (
    <div className="main-container">
      <div className="bat-hanging"></div>
      <div className="layout-container">
        <div className="bg-video"></div>

        <div className="bg-title">
          <img src={bitcoinText} alt="bitcoin text" className="title" />
        </div>
        <Outlet />
      </div>
    </div>
  );
};
export default Layout;
