import React, { useRef, useEffect, useState } from "react";
import { useAudio } from "../../audioContext";
import audio from "../../assets/music/Bitcoin-1.mp3";

const AudioPlayer = ({ children }) => {
  const audioRef = useRef(null);
  const { isPlaying } = useAudio();
  // const [userInteracted, setUserInteracted] = useState(false);

  // Handle user interaction (from anywhere in the document)
  // useEffect(() => {
  //   const handleInteraction = () => {
  //     setUserInteracted(true);
  //   };
  //   window.addEventListener("click", handleInteraction, {once: true});
  //   window.addEventListener("touchstart", handleInteraction, {once: true});

  //   return () => {
  //     window.removeEventListener("click", handleInteraction);
  //     window.removeEventListener("touchstart", handleInteraction);
  //   };
  // }, []);

  const handlePlay = () => {
    try {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    } catch (err) {
      console.error("Failed to play audio:", err);
    }
  };
  useEffect(() => {
    handlePlay();
  }, [isPlaying]);
  // useEffect(() => {
  //   if (isPlaying && userInteracted) {
  //     audioRef.current.play().catch((err) => console.log(err));
  //   } else {
  //     audioRef.current.pause();
  //     audioRef.current.currentTime = 0;
  //   }
  // }, [isPlaying, userInteracted]);

  return (
    <div>
      <audio ref={audioRef} src={audio} preload="auto" />

      {children}
    </div>
  );
};

export default AudioPlayer;
