import React from "react";
import { useNavigate } from "react-router-dom";
import ImageFrame from "../../components/ImageFrame";
import "./styles.css";

const aboutPara = (
  <>
    <p>
      {" "}
      <b> The First Bats on Bitcoin</b>
      <br></br>
      When the moon rises and the night deepens, beware, for Bitcoin Bats smell
      your blood... but don’t worry, you can’t hide! Under the cover of
      darkness, these mischievous bats emerge from their secret lairs—whether
      it's a cave, a bridge, or maybe even under your bed—to protect the Bitcoin
      blockchain.
    </p>{" "}
    <p>
      {" "}
      As the first of their kind on Bitcoin and Ordinals, Bitcoin Bats are on a
      mission. But it’s not just any mission. They’re here to save the Bitcoin
      world one meme at a time. No Freakin roadmaps to follow—just chaos, fun,
      and a whole lot of bite. Bitcoin Bats soar high, swoop low, and spread
      humor along the way. You might see them flying through your Twitter feed,
      sprinkling the blockchain with dank memes and plenty of batty antics. Why
      take things too seriously when you can have a blast? Whether you’re
      lurking under the night sky or scrolling endlessly through crypto Twitter,
      Bitcoin Bats are there, laughing, swooping, and ensuring you feel the fun.{" "}
    </p>
    <p>The bats are waiting. Don’t hesitate.</p>
  </>
);

const About = () => {
  const navigate = useNavigate();

  const handleClose = () => navigate("/");

  return (
    <div className="about-container">
      <ImageFrame
        title="about bitcoin bats"
        content={aboutPara}
        className="medium-text"
        frameSize="medium-frame"
        handleClose={handleClose}
        closeIcon={true}
      />
    </div>
  );
};

export default About;
