import React from "react";
import { Outlet } from "react-router-dom";
import bgVideo from "../../assets/video/background-video.mp4";
import bitcoinText from "../../assets/images/BitcoinBatText.png";
import "./style.css";

const Layout = () => {
  return (
    <div className="main-container">
      <div className="bat-tree"></div>
      <div className="layout-container">
        <div className="bg-video"></div>
        <div className="bg-title">
          <img src={bitcoinText} alt="bitcoin text" className="title" />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
