import { createBrowserRouter } from "react-router-dom";
import AuthProvider from "../hook";
import AuthLayout from "../components/AuthLayout";
import Layout from "../components/Layout";
import Error404 from "../components/Error404";
import Home from "../pages/home";
import About from "../pages/about";
import Manifesto from "../pages/manifesto";
import BatList from "../pages/batList";
import Links from "../pages/links";
import Collabs from "../pages/collabs";
// import Music from "../pages/music";
import Login from "../pages/login";
import Memes from "../pages/memes";
import Logout from "../hook/Logout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <AuthProvider>
            <Home />
          </AuthProvider>
        ),
      },
      { path: "/about", element: <About /> },
      { path: "/manifesto", element: <Manifesto /> },
      { path: "/", element: <Home /> },
      {
        path: "/batlist",
        element: (
          <AuthProvider>
            <BatList />
          </AuthProvider>
        ),
      },
      { path: "/links", element: <Links /> },
      { path: "/collabs", element: <Collabs /> },
      { path: "/memes", element: <Memes /> },
      { path: "/logout", element: <Logout /> },
    ],
  },
  {
    path: "/login",
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: (
          <AuthProvider>
            <Login />
          </AuthProvider>
        ),
      },
    ],
  },
  { path: "*", element: <Error404 /> },
]);

export default router;
