import {Link} from "react-router-dom";
import {useAudio} from "../../audioContext";
import "./styles.css";

function NavItem({text, icon, link}) {
  const {handlePlay, handleStop, isPlaying} = useAudio();

  const handleMusic = () => {
    if (!isPlaying) {
      handlePlay();
    } else {
      handleStop();
    }
  };
  return (
    <>
      <Link to={text === "Music" ? "#" : link} className="nav-item">
        <img
          src={icon}
          alt="Button"
          className="icon"
          onClick={text === "Music" ? handleMusic : null}
        />
        <div className="nav-item-text">{text}</div>
      </Link>
    </>
  );
}

export default NavItem;
