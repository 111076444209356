import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageFrame from "../../components/ImageFrame";
import "./styles.css";

const list = (
  <ul>
    <li>To be on the BATLIST:</li>
    <li>* Follow us on Twitter</li>
    <li>* Like and retweet and tag your friends on the pinned tweet</li>
    <li>* Like and retweet every new tweet</li>
    <li>* Turn on your notifications</li>
    <li>* Submit your ordinal address</li>
  </ul>
);

const message = (
  <p>
    Submit or share a tweet or memes post on X mentioning @BTCBats and earn some
    points{" "}
  </p>
);

const BatList = () => {
  const user = JSON.parse(localStorage.getItem("isLogin"));
  const [pointsValue, setPoints] = useState("");
  const navigate = useNavigate();
  const handleClose = () => navigate("/");
  const handlePoints = (points) => {
    setPoints(points);
  };
  useEffect(() => {
    setPoints(user.points);
  }, [user]);
  const points = (
    <>
      <p style={{}}>Your points: {pointsValue}</p>
      <p>Username: @{user.username}</p>
    </>
  );
  console.log(points, "dgffd");
  return (
    <div className="batList-container">
      <ImageFrame
        title="Bat List"
        content={list}
        handleClose={handleClose}
        isBatList={true}
        closeIcon={true}
        frameSize="medium-frame"
      />
      <div className="form-container">
        <ImageFrame
          title=""
          content={message}
          handleClose={handleClose}
          frameSize="xs-small-frame"
          closeIcon={false}
        />
        <ImageFrame
          title=""
          content={points}
          handleClose={handleClose}
          frameSize="x-small-frame"
          closeIcon={false}
          isSocialForm={true}
          updateData={handlePoints}
        />
      </div>
    </div>
  );
};

export default BatList;
