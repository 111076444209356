import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuth = localStorage.getItem("isLogin");

    if (isAuth) {
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return <>{children}</>;
};

export default AuthProvider;
